import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/home/HomePage.vue'
import Pony4KProject from '@/pages/pony4kproject/Pony4KProjectPage.vue'
import ErrorPage from "@/pages/error/ErrorPage.vue";

Vue.use(Router)


export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '/4k-pony-project',
            component: Pony4KProject
        },
        {
            path: '*',
            component: ErrorPage,
            props: { error: "Page not found" }
        }
    ]
})