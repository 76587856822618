<template>
    <footer class="section footer-area footer-bg">
        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left text-white-50">I pony c'entrano sempre.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>