import { render, staticRenderFns } from "./Pony4KProjectPage.vue?vue&type=template&id=12fe71be&scoped=true"
import script from "./Pony4KProjectPage.vue?vue&type=script&lang=js"
export * from "./Pony4KProjectPage.vue?vue&type=script&lang=js"
import style0 from "./Pony4KProjectPage.vue?vue&type=style&index=0&id=12fe71be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fe71be",
  null
  
)

export default component.exports