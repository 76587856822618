<template>
    <div class="london bg-dark">
        <div class="all-area">
            <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
                <!-- ***** Error Two Area Start ***** -->
                <div class="error-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-8 col-md-6 mx-auto mb-5 pt-4">
                                <img src="assets/img/sunset.png" alt="">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-10 col-lg-8 mx-auto text-center mb-5">
                                <h2>{{ error }}</h2>
                                <p class="my-4">{{ description }}</p>
                                <a href="/" class="btn btn-warning"><span>Go Home</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "ErrorPage",
    props: {
        error: String,
        description: String
    }
}
</script>

<style>

</style>