<template>
    <section class="section about-app-area my-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- About Text -->
                    <div class="about-text">
                        <h3 class="my-2 text-white">My Little Pony episodes rendered in <b>native 4K</b>!</h3>
                        <p class="my-2">
                            Tools that were used to do this are
                            <a class="text-warning" href="https://github.com/Shimmermare/MLP-4K-Tools">available
                                on GitHub</a> with a step-by-step guide.
                        </p>
                        <h3 class="my-2 text-danger">Project is abandoned because I don't have as much free time as back
                            then anymore.</h3>
                        <h3 class="my-2">
                            <a class="text-info" href="https://www.youtube.com/watch?v=TKGJbMD9zgg">Instead, check this
                                AI upscaling done by ChaseMMD - it's as good as manual 4K! (link)</a>
                        </h3>
                        <h3 class="mt-4 mb-2 text-white">Technically possible, but never processed:</h3>
                        <p>S04E21 "Testing Testing 1, 2, 3" <span class="text-muted">(tools ready)</span></p>
                        <p>S05E06 "Appleoosa's Most Wanted"</p>
                        <p>S06E24 "Top Bolt"</p>
                        <p>S08E19 "Road to Friendship" <span class="text-danger">(only FLAs)</span></p>
                        <p>S09E22 "Growing Up is Hard to Do"</p>
                        <p>S09E24 "The Ending of The End" (Part 1)</p>
                        <p class="mt-4">
                            Thanks to: <b>Bighckintosh</b> for providing the sources, <b>YayMuffins</b>,
                            <b>SandPox</b>, <b>Rainboom Dash</b>, and other members of YayPonies discord for help,
                            <b>ChaseMMD</b> for using this for AI upscale project.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- About Thumb -->
                    <div class="about-thumb text-center d-none d-lg-block">
                        <a href="assets/img/pony4kproject/823_CN-fullres.png" target="_blank"><img
                            src="assets/img/pony4kproject/823_CN.png" alt=""></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
</style>