<template>
    <div class="col-12 col-md-6">
        <div class="single-blog res-margin">
            <div class="blog-thumb">
                <a :href="imageUrl" target="_blank">
                    <img :src="previewUrl" alt="">
                </a>
            </div>
            <div class="blog-content p-4">
                <ul class="meta-info d-flex justify-content-between">
                    <li>By <a href="#">{{ author }}</a></li>
                    <li><a href="#">{{
                            date.toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                            })
                        }}</a></li>
                </ul>
                <h3 class="blog-title text-warning my-3">{{ name }}</h3>
                <p>{{ description }}</p>
                <span v-for="download in downloads" :key="download.name">
                    <a :href="download.url" :title="download.title" class="blog-btn mt-3 mr-3" target="_blank">▼
                        {{ download.name }} ({{ download.size }})</a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "EpisodeCard",
    props: {
        name: String,
        author: String,
        date: Date,
        description: String,
        previewUrl: String,
        imageUrl: String,
        downloads: Array,
    }
}
</script>

<style scoped>

</style>