<template>
    <div class="london bg-dark blog">
        <div class="all-area">
            <HeaderSection/>
            <div class="py-5">
                <section class="d-flex justify-content-center my-5">
                    <h2 class="text-capitalize text-warning">4K Pony Project</h2>
                </section>
                <AboutSection/>
                <section class="d-flex justify-content-center my-5">
                    <h3 class="text-white">Downloads (Torrent only)</h3>
                </section>
                <section id="blog" class="section blog-area my-5">
                    <div class="container">
                        <div class="row">
                            <EpisodeCard
                                name='S02E14 "The Last Roundup"'
                                author="Shimmermare"
                                :date="new Date(2019, 11, 13)"
                                description='As this episode had only Flash sources available, some of post-production effects are missing, though I tried to replicate them as much as I can. Also, the sources had Derpy censored, so I manually "uncensored" her.'
                                previewUrl="assets/img/pony4kproject/214.png"
                                imageUrl="assets/img/pony4kproject/214-fullres.png"
                                :downloads='[
                                {
                                    name: "H.264",
                                    title: "Download H.264 at 10/15 Mbps",
                                    url: "magnet:?xt=urn:btih:e819ce4f1f2f2e76355f0302863df627200af815&xt=urn:btmh:122010cd56a39ee2f449d6084f7dcec7abf17847b943098282f7762618eae74b4c96&dn=MLP-4K-S02E14-h264.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "1.7 GB"
                                },
                                {
                                    name: "H.265",
                                    title: "Download H.264 at 10/15 Mbps",
                                    url: "magnet:?xt=urn:btih:70b056e30f9ace93e844b92fd9d42ddf021e1cff&xt=urn:btmh:122036bbc703deee436cc8817aae5f779e4969f1e4003422b8d41733bcffb1681a12&dn=MLP-4K-S02E14-h265.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "1.8 GB"
                                },
                                {
                                    name: "ProRes 422 Proxy",
                                    title: "Download Apple ProRes 422 Proxy at 100 Mbps",
                                    url: "magnet:?xt=urn:btih:0e8a393b672f401e112c05a626cfd8f348dcd255&xt=urn:btmh:122083c91d0572dac6d72e2a9d5b0bc29a2a34384fa8490157aac367d036325b7c51&dn=MLP-4K-S02E14-ProRes422Proxy.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "17 GB"
                                },
                                {
                                    name: "ProRes 4444",
                                    title: "Download Apple ProRes 4444 at 658 Mbps",
                                    url: "magnet:?xt=urn:btih:961eceb7c1758621e0c5532e46a08b512935241a&xt=urn:btmh:122027ca0eaf920ce99c040ee010b4c723f43cb1f8a29d75e1092175e464a8e53bdf&dn=MLP-4K-S02E14-ProRes4444.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "109 GB"
                                },
                                {
                                    name: "Project",
                                    title: "Download project source",
                                    url: "magnet:?xt=urn:btih:162abea84c7adcd225df812abbb08fd96ecd81f1&xt=urn:btmh:12208d141373035f4c667e33609710806d65871ef4dccf42449236a3da0e56afa3e0&dn=MLP214_4K_2020_01_16.zip&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "83 GB"
                                }
                            ]'
                            />
                            <EpisodeCard
                                name='S05E09 "Slice of Life"'
                                author="Shimmermare"
                                :date="new Date(2021, 1, 10)"
                                previewUrl="assets/img/pony4kproject/509.png"
                                imageUrl="assets/img/pony4kproject/509-fullres.png"
                                :downloads='[
                                {
                                    name: "H.264",
                                    title: "Download H.264 at 10/15 Mbps",
                                    url: "magnet:?xt=urn:btih:15eeeea20f4eca35a7978803e87fb3706316a415&xt=urn:btmh:1220eb0763fab7a58c5c25571427ee081c73aa4d401f3d28cb5d779934d54708c2fe&dn=MLP-4K-S05E09-h264.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce",
                                    size: "1.8 GB"
                                },
                                {
                                    name: "H.265",
                                    title: "Download H.265 at 10/15 Mbps",
                                    url: "magnet:?xt=urn:btih:b9b8bd9f809e8f65e51e776888bca4f61abe5d17&xt=urn:btmh:1220cc1a6238aecf393431f4214e837491178d6580a59a6bc8c2519299fa07980cff&dn=MLP-4K-S05E09-h265.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "1.8 GB"
                                },
                                {
                                    name: "ProRes 422 Proxy",
                                    title: "Download Apple ProRes 422 Proxy at 102 Mbps",
                                    url: "magnet:?xt=urn:btih:80984fc92f62a60159f66c5cb82117834e682d83&xt=urn:btmh:1220ba4f67b40929a59aedface94420a49f8eb32ad704d6423f8d028651d402e8aef&dn=MLP-4K-S05E09-ProRes422Proxy.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "17 GB"
                                },
                                {
                                    name: "ProRes 4444",
                                    title: "Download Apple ProRes 4444 at 694 Mbps",
                                    url: "magnet:?xt=urn:btih:9d6ed6cff9c7d4db66817b5dbfa242cf0d4fc7fa&xt=urn:btmh:1220b0326fc3d4e4e270e327dc52fddc3b73a79c1b7f36698db24783fd9acb0c584b&dn=MLP-4K-S05E09-ProRes4444.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "114 GB"
                                },
                                {
                                    name: "Project",
                                    title: "Download project source",
                                    url: "magnet:?xt=urn:btih:4168b08f41e9c6bf992e8bd002246b73177fad3a&xt=urn:btmh:1220e94732744f0da92fcb9ae23724d754f4c63886490f88dc15ee26e6778f673b1d&dn=MLP509_4K_2020_01_13.zip&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "71 GB"
                                }
                            ]'
                            />
                            <EpisodeCard
                                name='S07E13 "The Perfect Pear"'
                                author="Shimmermare"
                                :date="new Date(2019, 11, 13)"
                                description="Unfortunately project files are lost, only scene list, After Effects layout, fix for scene 044 are preserved. Renders except for H.265 are lost too."
                                previewUrl="assets/img/pony4kproject/713.png"
                                imageUrl="assets/img/pony4kproject/713-fullres.png"
                                :downloads='[
                                {
                                    name: "H.265",
                                    title: "Download H.265",
                                    url: "magnet:?xt=urn:btih:02a172b3493558a34647356770bd4a807a2a22b8&xt=urn:btmh:1220d31f97b0b625121c045ea90edb692485a5f580ae2848f2e9c3b7a547cf4e7c31&dn=MLP-4K-S07E13-HEVC.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "4.2 GB"
                                },
                                {
                                    name: "Layout",
                                    title: "Project layout",
                                    url: "magnet:?xt=urn:btih:7cf00dd610cd76b8cbca71ab0aa7166cca736fa8&xt=urn:btmh:12205793b2afe42e2241196cb2feaccecc41a9727a1b215bdb977b383af538ec22bd&dn=MLP713_4K_PROJECT_LAYOUT.7z&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "152 KB"
                                }
                            ]'
                            />
                            <EpisodeCard
                                name='S08E23 "Sounds of Silence"'
                                author="Shimmermare"
                                :date="new Date(2020, 1, 10)"
                                previewUrl="assets/img/pony4kproject/823.png"
                                imageUrl="assets/img/pony4kproject/823-fullres.png"
                                :downloads='[
                                {
                                    name: "H.264",
                                    title: "Download H.264 at 10/15 Mbps",
                                    url: "magnet:?xt=urn:btih:d61d8ac732410c0051672b1af5238eed5f4337ec&xt=urn:btmh:12207280cdf5fb31577506021ff11126adf582d01ce9329f6c59aa00365f4c595ea6&dn=MLP-4K-S08E23-h264.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "1.7 GB"
                                },
                                {
                                    name: "H.265",
                                    title: "Download H.265 at 10/15 Mbps",
                                    url: "magnet:?xt=urn:btih:b0c082c4071e03deaa29f85c153a63fb7ef0f878&xt=urn:btmh:1220932b0402caf835ccebb1b9a84d8c1ccc9c45974c96092f2a2132100c25b61120&dn=MLP-4K-S08E23-h265.mkv&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce",
                                    size: "1.7 GB"
                                },
                                {
                                    name: "ProRes 422 Proxy",
                                    title: "Download Apple ProRes 422 Proxy at 115 Mbps",
                                    url: "magnet:?xt=urn:btih:3359aad3a475107d5999f7a2d7b2e5c2a87991bd&xt=urn:btmh:1220cd00adf4cf013aade9cc28d30a76b5358b956031b369f13b6796a2425b1dfce7&dn=MLP-4K-S08E23-ProRes422Proxy.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce",
                                    size: "19 GB"
                                },
                                {
                                    name: "ProRes 4444",
                                    title: "Download Apple ProRes 4444 at 847 Mbps",
                                    url: "magnet:?xt=urn:btih:6f10a1c3764789706601b118cc38a82aaa74c81a&xt=urn:btmh:1220549c13bcb39acc36d02e80e6831c0e203457e65390afeb86672d27cd5ed9a2f4&dn=MLP-4K-S08E23-ProRes4444.mkv&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce",
                                    size: "140 GB"
                                },
                                {
                                    name: "Project",
                                    title: "Download project source",
                                    url: "magnet:?xt=urn:btih:e1318eb0b732ebb8b5f88785861831ae09af1def&xt=urn:btmh:122033e50f1c8c72b8faba81c027e2bb5f5aa0447182e7c7905951e9410c487d2752&dn=MLP823_4K_2021_01_13.zip&tr=http%3a%2f%2ftracker.openbittorrent.com%3a80%2fannounce&tr=udp%3a%2f%2ftracker.opentrackr.org%3a1337%2fannounce&tr=https%3a%2f%2fopentracker.i2p.rocks%3a443%2fannounce",
                                    size: "71 GB"
                                }
                            ]'
                            />
                        </div>
                    </div>
                </section>
            </div>
            <FooterSection/>
        </div>
    </div>
</template>

<script>
import HeaderSection from '@/components/Header/HeaderSection.vue'
import AboutSection from './AboutSection.vue'
import FooterSection from '@/components/Footer/FooterSection.vue'
import EpisodeCard from "@/pages/pony4kproject/EpisodeCard.vue";

export default {
    name: 'Pony4KProjectPage',
    components: {
        EpisodeCard,
        HeaderSection,
        AboutSection,
        FooterSection
    }
}
</script>

<style scoped>
a:disabled, a[disabled] {
    text-decoration: line-through !important;
}
</style>